import React, { useState, useEffect } from "react"

type Props = {
  target: React.RefObject<HTMLElement>
}

const ReadingProgress = ({ target }: Props) => {
  const [readingProgress, setReadingProgress] = useState(0)
  const scrollListener = () => {
    if (!target.current) {
      return
    }
    const element = target.current
    const totalHeight =
      element.clientHeight - element.offsetTop - window.innerHeight
    const windowScrollTop =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    if (windowScrollTop === 0) {
      return setReadingProgress(0)
    }
    if (windowScrollTop > totalHeight) {
      return setReadingProgress(100)
    }
    setReadingProgress((windowScrollTop / totalHeight) * 100)
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollListener)
    return () => window.removeEventListener("scroll", scrollListener)
  })

  return (
    <div
      className={`reading-progress-bar`}
      style={{
        position: "fixed",
        height: "2px",
        top: 0,
        backgroundColor: "#00a697",
        zIndex: 1000,
        width: `${readingProgress}%`,
      }}
    />
  )
}

export default ReadingProgress
