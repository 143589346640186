import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout"
import MetaData from "../components/meta"
import ReadingProgress from "../components/ReadingProgress"

// Import styles
import "../styles/blog-post.scss"
import "../styles/press.scss"

// Images
import ArrowBlue from "../images/inline-svgs/right-arrow-blue.svg"
import Twitter from "../images/inline-svgs/twitter.svg"
import Linkedin from "../images/inline-svgs/linkedin.svg"

interface Props {
  data: any
}

const PressPost = ({ data, location }: Props) => {
  const post = data.ghostPost
  const progress_bar_target = React.createRef()

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />

      <ReadingProgress target={progress_bar_target} />

      {/* Add codeinjection scripts */}
      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: post.codeinjection_head }}
      ></div>

      <div className="post-view post-view--press">
        <div className="wrapper-1200">
          <article ref={progress_bar_target}>
            <header className="post__header">
              {/* Back button */}
              <div className="post__primary-tag">
                <Link to={`/press/`} className="go-back-link">
                  <span className="icon">
                    <ArrowBlue />
                  </span>
                  <span className="text">Back to Press Releases</span>
                </Link>
              </div>

              <h1 className="post__title">{post.title}</h1>

              <div className="post__meta">
                <div className="post__date">{post.published_at_pretty}</div>
              </div>
            </header>

            <div className="post__body">
              <div
                className="post-full-content load-external-scripts"
                dangerouslySetInnerHTML={{ __html: post.html }}
              ></div>
            </div>
          </article>

          <aside>
            <div className="aside__wrapper">
              <div className="post__sharing">
                <div className="post__sharing-title">Share:</div>
                <div className="post__sharing-icons">
                  <button
                    className="post__sharing-icon"
                    onClick={() => {
                      window.open(
                        `https://twitter.com/share?text=${post.title}&amp;url=${window.location.href}`,
                        "share-twitter",
                        "width=550,height=235"
                      )
                      return false
                    }}
                  >
                    <span className="icon icon--twitter">
                      <Twitter />
                    </span>
                  </button>

                  <button
                    className="post__sharing-icon"
                    onClick={() => {
                      window.open(
                        `https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=${post.title}&summary=${post.excerpt}`,
                        "share-linkedin",
                        "width=550,height=235"
                      )
                      return false
                    }}
                  >
                    <span className="icon icon--linkedin">
                      <Linkedin />
                    </span>
                  </button>
                </div>
              </div>

              <div className="press-contact-card">
                <div className="wrapper">
                  <div className="email-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="13"
                      viewBox="0 0 20 13"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path d="M-2-5h24v24H-2z" />
                        <path
                          fill="#D2DCF6"
                          d="M19.997 1.973L20 2v9c0 1.102-.897 2-2 2H2c-1.103 0-2-.898-2-2V2c0-.008.003-.017.003-.026l9.47 5.877a1 1 0 0 0 1.054-.001zM18 .001c.352 0 .677.1.965.26L10 5.824 1.035.261c.287-.16.613-.26.965-.26z"
                        />
                      </g>
                    </svg>
                  </div>
                  <h3>Press Contact</h3>
                  <a
                    href="mailto:press@traefik.io"
                    className="button--primary"
                  >
                    press@traefik.io
                  </a>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export default PressPost

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`
